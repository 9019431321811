/**
 * Triggers analytics page view event.
 *
 * @param page - Page name/pathname.
 */
export function pageView(page) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "pageview",
      page,
    });
  }
}

/**
 * Triggers analytics event.
 */
export function event() {
  if (window && window.gtag) {
    window.gtag(arguments);
  }
}
