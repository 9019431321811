import { searchHoldingsQueryGuard } from "@aft/value-guards/searchHoldingsQueryGuard";

import {
  GET_HOLDINGS_LIST_REQUEST,
  GET_HOLDINGS_LIST_SUCCESS,
  GET_PREDICTIONS_DATES_RANGE_REQUEST,
  GET_PREDICTIONS_DATES_RANGE_SUCCESS,
} from "./actionTypes";

export const holdingsReducerInitialState = {
  query: searchHoldingsQueryGuard({}),
  records: [],
  pages: 0,
  total: 0,

  predictionsDatesRange: {},
};

/**
 * Reducer, responsible for handling actions and store state relative to holdings data.
 *
 * @param state - Current state of the reducer.
 * @param action - Action to be applied.
 */
export const holdingsReducer = (state = holdingsReducerInitialState, action) => {
  switch (action.type) {
    case GET_HOLDINGS_LIST_REQUEST:
      return {
        ...state,

        query: action.query,
        records: [],
        pages: 0,
        total: 0,
        ...(action.resetSelection
          ? {
              selectedHoldingSymbol: undefined,
            }
          : {}),
      };
    case GET_PREDICTIONS_DATES_RANGE_REQUEST:
      return {
        ...state,

        predictionsDatesRange: {},
      };
    case GET_HOLDINGS_LIST_SUCCESS:
      return {
        ...state,

        records: action.records,
        pages: action.pages,
        total: action.total,
        ...(state.selectedHoldingSymbol !== undefined &&
        action.records.some(({ symbol }) => symbol === state.selectedHoldingSymbol)
          ? {}
          : { selectedHoldingSymbol: undefined }),
      };
    case GET_PREDICTIONS_DATES_RANGE_SUCCESS:
      return {
        ...state,

        predictionsDatesRange: action.predictionsDatesRange,
      };
    default:
      return state;
  }
};
