import {
  POST_TECHNICAL_SUPPORT_REQUEST_REQUEST,
  POST_TECHNICAL_SUPPORT_REQUEST_SUCCESS,
  POST_TECHNICAL_SUPPORT_REQUEST_FAILURE,
} from "./actionTypes";

/**
 * Post technical support request request action.
 *
 * @param data - Request data.
 */
export const postTechnicalSupportRequestRequest = (data) => ({
  type: POST_TECHNICAL_SUPPORT_REQUEST_REQUEST,
  data,
});

/**
 * Callback action for successful post technical support request attempt.
 */
export const postTechnicalSupportRequestSuccess = () => ({
  type: POST_TECHNICAL_SUPPORT_REQUEST_SUCCESS,
});

/**
 * Callback action for failed post technical support request attempt.
 *
 * @param error - Error message.
 */
export const postTechnicalSupportRequestFailure = (error) => ({
  type: POST_TECHNICAL_SUPPORT_REQUEST_FAILURE,
  error,
});
