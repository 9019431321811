import {
  faChartBar,
  faUserCog,
  faFileContract,
  faQuestionCircle,
  faPollH,
} from "@fortawesome/free-solid-svg-icons";

/**
 * Names of the route segments.
 */
export const RoutesSegments = {
  Account: "account",
  Authorization: "authorization",
  Cancel: "cancel",
  Confirm: "confirm",
  Edit: "edit",
  Email: "email",
  Info: "info",
  Overview: "overview",
  Password: "password",
  PaymentMethod: "payment-method",
  Phone: "phone",
  PlanSelection: "plan-selection",
  Portfolio: "portfolio",
  Privacy: "privacy",
  Request: "request",
  Reset: "reset",
  Security: "security",
  Settings: "settings",
  SignIn: "sign-in",
  SignUp: "sign-up",
  Subscription: "subscription",
  Support: "support",
  Survey: "survey",
  Technical: "technical",
  Terms: "terms",
  TermsOfService: "terms-of-service",
  Verification: "verification",
};

/**
 * Set of all client routes.
 * Based on the root (/) route object.
 */
export const RootRouteObjectConfig = {
  nested: {
    /**
     * Authorization page(s).
     */
    [RoutesSegments.Authorization]: {
      notExists: true,
      nested: {
        /**
         * Sign up page(s).
         */
        [RoutesSegments.SignUp]: {},

        /**
         * Pages for performing authorization actions relative to phone number.
         */
        [RoutesSegments.Phone]: {
          notExists: true,
          nested: {
            /**
             * Phone verification page(s).
             */
            [RoutesSegments.Verification]: {},
          },
        },

        /**
         * Pages to perform authorization actions relative to email.
         */
        [RoutesSegments.Email]: {
          notExists: true,
          nested: {
            /**
             * Confirm email page(s).
             */
            [RoutesSegments.Confirm]: {},
          },
        },

        /**
         * Pages to perform authorization actions relative to password.
         */
        [RoutesSegments.Password]: {
          notExists: true,
          nested: {
            /**
             * Reset password page(s).
             */
            [RoutesSegments.Reset]: {
              nested: {
                /**
                 * Reset password request page(s).
                 */
                [RoutesSegments.Request]: {},
              },
            },
          },
        },
      },
    },

    /**
     * Terms page(s).
     */
    [RoutesSegments.Terms]: {
      notExists: true,
      nested: {
        /**
         * Terms of service page(s).
         */
        [RoutesSegments.TermsOfService]: {
          nameTranslationKey: "Navigation.Terms.TermsOfService",
          faIcon: faFileContract,
        },
      },
    },

    /**
     * Portfolio page(s).
     */
    [RoutesSegments.Portfolio]: {
      notExists: true,
      nested: {
        /**
         * Portfolio overview page(s).
         */
        [RoutesSegments.Overview]: {
          nameTranslationKey: "Navigation.Portfolio.Overview",
          faIcon: faChartBar,
        },
      },
    },

    /**
     * Subscription page(s).
     */
    [RoutesSegments.Subscription]: {
      notExists: true,
      nested: {
        /**
         * Subscription plan selection page(s).
         */
        [RoutesSegments.PlanSelection]: {},
      },
    },

    /**
     * Payment method page(s).
     */
    [RoutesSegments.PaymentMethod]: {},

    /**
     * Account page(s).
     */
    [RoutesSegments.Account]: {
      notExists: true,
      nested: {
        /**
         * Account settings page(s).
         */
        [RoutesSegments.Settings]: {
          nameTranslationKey: "Navigation.Account.Settings",
          faIcon: faUserCog,
          nested: {
            /**
             * Account payment method settings page(s).
             */
            [RoutesSegments.PaymentMethod]: {
              nameTranslationKey: "Navigation.Account.Settings.PaymentMethod",
            },

            /**
             * Account subscription settings page(s).
             */
            [RoutesSegments.Subscription]: {
              nameTranslationKey: "Navigation.Account.Settings.Subscription",
              nested: {
                /**
                 * Account subscription settings cancel page(s).
                 */
                [RoutesSegments.Cancel]: {
                  nameTranslationKey: "Navigation.Account.Settings.Subscription.Cancel",
                },
              },
            },

            /**
             * Account security settings page(s).
             */
            [RoutesSegments.Security]: {
              nameTranslationKey: "Navigation.Account.Settings.Security",
            },

            /**
             * Account info settings page(s).
             */
            [RoutesSegments.Info]: {
              nameTranslationKey: "Navigation.Account.Settings.Info",
              nested: {
                /**
                 * Account info settings edit page(s).
                 */
                [RoutesSegments.Edit]: {
                  nameTranslationKey: "Navigation.Account.Settings.Info.Edit",
                  nested: {
                    /**
                     * Account settings info email edit page(s).
                     */
                    [RoutesSegments.Email]: {
                      nameTranslationKey: "Navigation.Account.Settings.Info.Edit.Email",
                      notExists: true,
                      nested: {
                        /**
                         * Confirm account settings info email edit page(s).
                         */
                        [RoutesSegments.Confirm]: {
                          nameTranslationKey: "Navigation.Account.Settings.Info.Edit.Email.Confirm",
                        },
                      },
                    },

                    /**
                     * Account settings info phone edit page(s).
                     */
                    [RoutesSegments.Phone]: {
                      nameTranslationKey: "Navigation.Account.Settings.Info.Edit.Phone",
                      notExists: true,
                      nested: {
                        /**
                         * Verify account settings info phone edit page(s).
                         */
                        [RoutesSegments.Verification]: {
                          nameTranslationKey:
                            "Navigation.Account.Settings.Info.Edit.Phone.Verification",
                        },
                      },
                    },
                  },
                },
              },
            },

            /**
             * Account privacy settings page(s).
             */
            [RoutesSegments.Privacy]: {
              nameTranslationKey: "Navigation.Account.Settings.Privacy",
            },
          },
        },
      },
    },

    /**
     * Support page(s).
     */
    [RoutesSegments.Support]: {
      notExists: true,
      nested: {
        /**
         * Technical support page(s).
         */
        [RoutesSegments.Technical]: {
          nameTranslationKey: "Navigation.Support.Technical",
          faIcon: faQuestionCircle,
        },
      },
    },

    /**
     * Survey page(s).
     */
    [RoutesSegments.Survey]: {
      nameTranslationKey: "Navigation.Survey",
      faIcon: faPollH,
    },
  },
};
