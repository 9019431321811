import { call, put, takeLatest } from "redux-saga/effects";

import { applicationSagas } from "@aft/client-modules/application";
import { serverApiClient } from "@aft/server/apiClient";

import { POST_TECHNICAL_SUPPORT_REQUEST_REQUEST } from "./actionTypes";
import { postTechnicalSupportRequestSuccess, postTechnicalSupportRequestFailure } from "./actions";

function* postTechnicalSupportRequest({ data }) {
  try {
    yield call(serverApiClient.support.postTechnicalSupportRequest, data);

    yield put(postTechnicalSupportRequestSuccess());
  } catch (err) {
    yield put(postTechnicalSupportRequestFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the support module.
 */
export function* supportSaga() {
  yield takeLatest(POST_TECHNICAL_SUPPORT_REQUEST_REQUEST, postTechnicalSupportRequest);
}
