/**
 * Set of possible number formats.
 */
const NumberFormats = {
  /**
   * Display as percents.
   */
  Percent: "Percent",

  /**
   * Short format with multiplication prefix.
   */
  Short: "Short",

  /**
   * Raw number without any conversions.
   */
  Long: "Long",
};

module.exports = {
  NumberFormats,
};
