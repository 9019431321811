import { call, put, takeLatest } from "redux-saga/effects";

import { applicationSagas } from "@aft/client-modules/application";
import { serverApiClient } from "@aft/server/apiClient";

import { GET_HOLDINGS_LIST_REQUEST, GET_PREDICTIONS_DATES_RANGE_REQUEST } from "./actionTypes";
import {
  getHoldingsListSuccess,
  getHoldingsListFailure,
  getPredictionsDatesRangeSuccess,
  getPredictionsDatesRangeFailure,
} from "./actions";

function* getHoldingsList({ query }) {
  try {
    const { docs, pages, total } = yield call(serverApiClient.holdings.getHoldingsList, query);

    yield put(getHoldingsListSuccess(docs, pages, total));
  } catch (err) {
    yield put(getHoldingsListFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

function* getPredictionsDatesRange() {
  try {
    const datesRange = yield call(serverApiClient.holdings.getPredictionsDatesRange);

    yield put(getPredictionsDatesRangeSuccess(datesRange));
  } catch (err) {
    yield put(getPredictionsDatesRangeFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the holdings module.
 */
export function* holdingsSaga() {
  yield takeLatest(GET_HOLDINGS_LIST_REQUEST, getHoldingsList);
  yield takeLatest(GET_PREDICTIONS_DATES_RANGE_REQUEST, getPredictionsDatesRange);
}
