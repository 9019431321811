import React, { Fragment, useEffect, useMemo, useState } from "react";

import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { appWithTranslation } from "next-i18next";
import NextApp from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import { compose } from "redux";

import { Loader } from "@aft/client-components/core/Loader";
import { ContinueSessionModal } from "@aft/client-components/session/modals/Continue";
import { ClientModules, clientModulesManager } from "@aft/client-modules";
import { applicationActions, applicationSelectors } from "@aft/client-modules/application";
import {
  authorizationActions,
  authorizationActionTypes,
  authorizationSelectors,
} from "@aft/client-modules/authorization";
import { clientRoutesManager } from "@aft/client-routes";
import { pageView } from "@aft/client-services/analytics";
import { settings } from "@aft/client-settings";

import { RootRouteObjectConfig, RoutesSegments } from "config/rootRouteObjectConfig";

import "react-phone-number-input/style.css";
import "@aft/client-styles/index.scss";

// Initialize client routes manager.
clientRoutesManager.initialize(RootRouteObjectConfig, RoutesSegments);
clientRoutesManager.rootNotAuthorizedRoute = clientRoutesManager.getRoute();
clientRoutesManager.rootAuthorizedRoute = clientRoutesManager.getRoute(
  clientRoutesManager.Segments.Subscription,
  clientRoutesManager.Segments.PlanSelection,
);
clientRoutesManager.rootSubscribedRoute = clientRoutesManager.getRoute(
  clientRoutesManager.Segments.Portfolio,
  clientRoutesManager.Segments.Overview,
);

// Initialize client modules manager.
clientModulesManager.initialize([
  ClientModules.Account,
  ClientModules.Application,
  ClientModules.Authorization,
  ClientModules.Holdings,
  ClientModules.Notifications,
  ClientModules.PaymentMethod,
  ClientModules.Phone,
  ClientModules.Subscription,
  ClientModules.Support,
  ClientModules.Survey,
]);

// Load stripe.
const stripePromise = loadStripe(settings.StripeService.PublicKey);

/**
 * Custom "_app" component.
 * Connects redux-saga to the application.
 * Initialize watching over routes changes for GTM.
 *
 * @param props - App component props.
 * @param props.Component - Child page component to render.
 * @param props.pageProps - Page props to pass to the rendered page.
 */
const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [sessionContinueAttempted, setSessionContinueAttempted] = useState(false);

  const isAnyAsyncActionLoading = useSelector(applicationSelectors.selectIsAnyAsyncActionLoading);
  const sessionContinueActionState = useSelector(
    applicationSelectors.createAsyncActionStateSelector(
      authorizationActionTypes.SESSION_CONTINUE_REQUEST,
    ),
  );
  const signInActionState = useSelector(
    applicationSelectors.createAsyncActionStateSelector(authorizationActionTypes.SIGN_IN_REQUEST),
  );
  const isSignedIn = useSelector(authorizationSelectors.selectIsSignedIn);

  useEffect(() => {
    // Validate is signed in to keep development mode behavior with its reloads similar to production.
    if (!sessionContinueAttempted) {
      dispatch(authorizationActions.sessionContinueRequest());
    } else {
      setSessionContinueAttempted(true);
    }

    const startPageLoading = () => {
      setIsPageLoading(true);
    };

    const endPageLoading = () => {
      setIsPageLoading(false);
    };

    const handleRouteChangeComplete = (pathname) => {
      dispatch(applicationActions.routeChangeComplete(pathname));
    };

    // Initiate GTM
    router.events.on("routeChangeComplete", pageView);
    // Process page loading state.
    router.events.on("routeChangeStart", startPageLoading);
    router.events.on("routeChangeComplete", endPageLoading);
    router.events.on("routeChangeError", endPageLoading);
    // Process route change.
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", pageView);
      router.events.off("routeChangeStart", startPageLoading);
      router.events.off("routeChangeComplete", endPageLoading);
      router.events.off("routeChangeError", endPageLoading);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !sessionContinueAttempted &&
      (sessionContinueActionState.success || sessionContinueActionState.error)
    ) {
      setSessionContinueAttempted(true);
    }
  }, [
    sessionContinueAttempted,
    sessionContinueActionState.success,
    sessionContinueActionState.error,
  ]);

  return useMemo(() => {
    const { requireSignInRepeat, ...props } = pageProps;
    const showContinueSessionModal =
      requireSignInRepeat && !isPageLoading && isSignedIn && !signInActionState.success;
    return (
      <>
        <Head>
          <title>Adaptive finance</title>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        </Head>
        <StripeElements stripe={stripePromise}>
          {sessionContinueAttempted
            ? (Component.getLayout || ((page) => page))(
                showContinueSessionModal ? null : <Component {...props} />,
              )
            : null}
          <Loader show={isPageLoading || isAnyAsyncActionLoading || !sessionContinueAttempted} />
          <ContinueSessionModal show={showContinueSessionModal} />
        </StripeElements>
      </>
    );
  }, [
    Component.getLayout,
    pageProps,
    isSignedIn,
    signInActionState.success,
    sessionContinueAttempted,
    isPageLoading,
    isAnyAsyncActionLoading,
  ]);
};

App.getInitialProps = async (context) => {
  const props = await NextApp.getInitialProps(context);

  return { ...props };
};

export default compose(clientModulesManager.reduxWrapper.withRedux, appWithTranslation)(App);
