import React, { memo, useMemo, useCallback, useEffect } from "react";

import { Formik } from "formik";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";

import { Notifications } from "@aft/client-components/core/Notifications";
import { FormInputField, Form } from "@aft/client-components/core/form";
import { authorizationActions } from "@aft/client-modules/authorization";
import { clientRoutesManager } from "@aft/client-routes";
import {
  FormFieldNames,
  createPasswordFieldValidator,
  createFormValidator,
} from "@aft/client-services/forms";
import { settings } from "@aft/client-settings";

const ContinueSessionModalComponent = memo(({ account: { user }, show, signInRequest }) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  useEffect(() => {
    if (show && !user.email) {
      router.push(clientRoutesManager.rootNotAuthorizedRoute.pathname);
    }
  }, [router, show, user.email]);

  const handleFormSubmit = useCallback(
    (values) => {
      signInRequest({
        [FormFieldNames.Email]: user.email,
        ...values,
      });
    },
    [user.email, signInRequest],
  );

  return useMemo(
    () => (
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Formik
          initialValues={{
            [FormFieldNames.Password]: settings.User.Default.Password || "",
          }}
          validate={createFormValidator(t, [createPasswordFieldValidator(FormFieldNames.Password)])}
          onSubmit={handleFormSubmit}
        >
          <Form>
            <Modal.Header>
              <Modal.Title>{t("ContinueSessionModal.Title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <Notifications />
              <div className="p-4">
                <Row className="my-n4">
                  <Col xs="12" className="py-4">
                    {t("ContinueSessionModal.Description")}
                  </Col>
                  <Col xs="12" className="py-4">
                    <Row className="my-n3">
                      <Col xs="12" className="py-3">
                        <FormInputField name={FormFieldNames.Password} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">{t("Buttons.Continue")}</Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    ),
    [t, show, handleFormSubmit],
  );
});

const mapStateToProps = ({ account, authorization }) => ({ account, authorization });

/**
 * Modal, responsible for displaying information about session has ended to the user
 * and offer him to re-enter his password in favour to continue session.
 */
export const ContinueSessionModal = connect(mapStateToProps, {
  signInRequest: authorizationActions.signInRequest,
})(ContinueSessionModalComponent);
