const { NumberFormats } = require("@aft/constants/general/numbers");

/**
 * Set of possible sectors, particular holding can belong to.
 */
const HoldingsSectors = {
  CommunicationServices: "Communication Services",
  ConsumerDiscretionary: "Consumer Discretionary",
  ConsumerStaples: "Consumer Staples",
  Energy: "Energy",
  Financials: "Financials",
  HealthCare: "Health Care",
  Industrials: "Industrials",
  InformationTechnology: "Information Technology",
  Materials: "Materials",
  RealEstate: "Real Estate",
  Utilities: "Utilities",
};

/**
 * Set of colors, representing particular holding sector.
 */
const HoldingsSectorsColors = {
  [HoldingsSectors.CommunicationServices]: "#B36DFD",
  [HoldingsSectors.ConsumerDiscretionary]: "#7291CF",
  [HoldingsSectors.ConsumerStaples]: "#038DDD",
  [HoldingsSectors.Energy]: "#949494",
  [HoldingsSectors.Financials]: "#669E2E",
  [HoldingsSectors.HealthCare]: "#FC0D1B",
  [HoldingsSectors.Industrials]: "#02ACA6",
  [HoldingsSectors.InformationTechnology]: "#CC0F64",
  [HoldingsSectors.Materials]: "#C18701",
  [HoldingsSectors.RealEstate]: "#4859A3",
  [HoldingsSectors.Utilities]: "#990000",
};

/**
 * Field names for the holdings table usage.
 */
const HoldingsFieldNames = {
  DateAndTime: "dttm",
  Symbol: "symbol",
  Name: "name",
  Sector: "sector",
  QmlScore: "qmlScore",
  Last: "last",
  Volume30Days: "volume30D",
  High52Weeks: "high52W",
  Low52Weeks: "low52W",
  RevenueTtm: "revenueTtm",
  MarketCap: "marketCap",
  PToERatio: "pToE",
  PToSRatio: "pToS",
  PToBRatio: "pToB",
  EvToEbitdaRatio: "evToEbitda",
};

/**
 * Formats for various fields from the holdings table.
 */
const HoldingsNumberFieldsFormats = {
  [HoldingsFieldNames.QmlScore]: NumberFormats.Long,
  [HoldingsFieldNames.Last]: NumberFormats.Long,
  [HoldingsFieldNames.Volume30Days]: NumberFormats.Short,
  [HoldingsFieldNames.High52Weeks]: NumberFormats.Long,
  [HoldingsFieldNames.Low52Weeks]: NumberFormats.Long,
  [HoldingsFieldNames.RevenueTtm]: NumberFormats.Short,
  [HoldingsFieldNames.MarketCap]: NumberFormats.Short,
  [HoldingsFieldNames.PToERatio]: NumberFormats.Long,
  [HoldingsFieldNames.PToSRatio]: NumberFormats.Long,
  [HoldingsFieldNames.PToBRatio]: NumberFormats.Long,
  [HoldingsFieldNames.EvToEbitdaRatio]: NumberFormats.Long,
};

module.exports = {
  HoldingsSectors,
  HoldingsSectorsColors,
  HoldingsNumberFieldsFormats,
  HoldingsFieldNames,
  HOLDINGS_DATE_FORMAT: "YYYY-MM-DD",
};
