import {
  POST_SURVEY_RESULTS_REQUEST,
  POST_SURVEY_RESULTS_SUCCESS,
  POST_SURVEY_RESULTS_FAILURE,
} from "./actionTypes";

/**
 * Post survey results request action.
 *
 * @param surveyData - Survey results data.
 */
export const postSurveyResultsRequest = (surveyData) => ({
  type: POST_SURVEY_RESULTS_REQUEST,
  surveyData,
});

/**
 * Callback action for successful post survey results request attempt.
 */
export const postSurveyResultsSuccess = () => ({
  type: POST_SURVEY_RESULTS_SUCCESS,
});

/**
 * Callback action for failed post survey results request attempt.
 *
 * @param error - Error message.
 */
export const postSurveyResultsFailure = (error) => ({
  type: POST_SURVEY_RESULTS_FAILURE,
  error,
});
