import moment from "moment";

import { HOLDINGS_DATE_FORMAT } from "@aft/constants/data/holdings";
import { searchHoldingsQueryGuard } from "@aft/value-guards/searchHoldingsQueryGuard";

import {
  GET_HOLDINGS_LIST_REQUEST,
  GET_HOLDINGS_LIST_SUCCESS,
  GET_HOLDINGS_LIST_FAILURE,
  GET_PREDICTIONS_DATES_RANGE_REQUEST,
  GET_PREDICTIONS_DATES_RANGE_SUCCESS,
  GET_PREDICTIONS_DATES_RANGE_FAILURE,
} from "./actionTypes";

/**
 * Request list of holdings list that matches specified search query.
 *
 * @param query - Search query.
 * @param resetSelection - Indicates whether to reset current selection on request or not.
 */
export const getHoldingsListRequest = (query, resetSelection = false) => ({
  type: GET_HOLDINGS_LIST_REQUEST,
  query: searchHoldingsQueryGuard(query),
  resetSelection,
});

/**
 * Callback action for successful holdings list request attempt.
 *
 * @param records - List of received holdings records.
 * @param pages - Total amount of pages.
 * @param total - Total amount of records.
 */
export const getHoldingsListSuccess = (records, pages, total) => ({
  type: GET_HOLDINGS_LIST_SUCCESS,
  records,
  pages,
  total,
});

/**
 * Callback action for failed holdings list request attempt.
 *
 * @param error - Error message.
 */
export const getHoldingsListFailure = (error) => ({
  type: GET_HOLDINGS_LIST_FAILURE,
  error,
});

/**
 * Request date range, where holdings predictions data exists.
 */
export const getPredictionsDatesRangeRequest = () => ({
  type: GET_PREDICTIONS_DATES_RANGE_REQUEST,
});

/**
 * Callback action for successful predictions date range request attempt.
 *
 * @param predictionsDatesRange - Date range.
 * @param predictionsDatesRange.start - Start date of the range.
 * @param predictionsDatesRange.end - End date of the range.
 */
export const getPredictionsDatesRangeSuccess = ({ start, end }) => ({
  type: GET_PREDICTIONS_DATES_RANGE_SUCCESS,
  predictionsDatesRange: {
    start: moment.utc(start, HOLDINGS_DATE_FORMAT, true),
    end: moment.utc(end, HOLDINGS_DATE_FORMAT, true),
  },
});

/**
 * Callback action for failed predictions date range request attempt.
 *
 * @param error - Error message.
 */
export const getPredictionsDatesRangeFailure = (error) => ({
  type: GET_PREDICTIONS_DATES_RANGE_FAILURE,
  error,
});
