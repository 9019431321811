/**
 * Set of possible subscription statuses.
 */
const SubscriptionStatuses = {
  /**
   * Active subscription.
   */
  Active: "active",

  /**
   * Subscription is past due.
   */
  PastDue: "past_due",

  /**
   * Subscription is unpaid after an charge attempt.
   */
  Unpaid: "unpaid",

  /**
   * Subscription is canceled.
   */
  Canceled: "canceled",

  /**
   * Payment to continue subscription is incomplete.
   */
  Incomplete: "incomplete",

  /**
   * Invoice for the payment responsible for subscription continuation is expired (after 23 hours).
   */
  IncompleteExpired: "incomplete_expired",

  /**
   * Subscription is in the trial period.
   */
  Trialing: "trialing",
};

/**
 * Set of possible intervals in a recurring subscriptions.
 */
const SubscriptionRecurringIntervals = {
  /**
   * Month interval.
   */
  Month: "month",

  /**
   * Year interval.
   */
  Year: "year",
};

module.exports = {
  SubscriptionStatuses,
  SubscriptionRecurringIntervals,
};
