import { call, put, takeLatest } from "redux-saga/effects";

import { accountActions } from "@aft/client-modules/account";
import { applicationSagas } from "@aft/client-modules/application";
import { subscriptionActions } from "@aft/client-modules/subscription";
import { serverApiClient } from "@aft/server/apiClient";

import { POST_SURVEY_RESULTS_REQUEST } from "./actionTypes";
import { postSurveyResultsSuccess, postSurveyResultsFailure } from "./actions";

function* postSurveyResults({ surveyData }) {
  try {
    const { user, upcomingPayment } = yield call(
      serverApiClient.survey.postSurveyResults,
      surveyData,
    );

    yield put(postSurveyResultsSuccess());
    yield put(accountActions.setCurrentUserData(user));
    yield put(subscriptionActions.setUpcomingPayment(upcomingPayment));
  } catch (err) {
    yield put(postSurveyResultsFailure(err.message));
    yield call(applicationSagas.handleApiError, err);
  }
}

/**
 * Root saga of the survey module.
 */
export function* surveySaga() {
  yield takeLatest(POST_SURVEY_RESULTS_REQUEST, postSurveyResults);
}
