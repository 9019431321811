import { accountReducer, accountSaga } from "@aft/client-modules/account";
import { applicationReducer } from "@aft/client-modules/application";
import { authorizationReducer, authorizationSaga } from "@aft/client-modules/authorization";
import { holdingsReducer, holdingsSaga } from "@aft/client-modules/holdings";
import { notificationsReducer, notificationsSaga } from "@aft/client-modules/notifications";
import { paymentMethodReducer, paymentMethodSaga } from "@aft/client-modules/paymentMethod";
import { phoneReducer, phoneSaga } from "@aft/client-modules/phone";
import { subscriptionReducer, subscriptionSaga } from "@aft/client-modules/subscription";
import { supportSaga } from "@aft/client-modules/support";
import { surveySaga } from "@aft/client-modules/survey";

/**
 * Set of client modules to be used in the application.
 */
export const ClientModules = {
  /**
   * Account module.
   * Responsible for current user's management.
   */
  Account: "account",

  /**
   * Application module.
   * Responsible for application state management.
   */
  Application: "application",

  /**
   * Authorization module.
   * Responsible for authorization state.
   */
  Authorization: "authorization",

  /**
   * Holdings module.
   * Responsible for management of the information, relative to the holdings.
   */
  Holdings: "holdings",

  /**
   * Notifications module.
   * Responsible for management of the UI notifications.
   */
  Notifications: "notifications",

  /**
   * Payment method module.
   * Responsible for managing actions, relative to the user's payment method.
   */
  PaymentMethod: "paymentMethod",

  /**
   * Phone module.
   * Responsible for managing actions, relative to the phone numbers.
   */
  Phone: "phone",

  /**
   * Subscription module.
   * Responsible for managing actions, relative to the user's subscription.
   */
  Subscription: "subscription",

  /**
   * Support module.
   * Responsible for managing actions, relative to the user's support.
   */
  Support: "support",

  /**
   * Support module.
   * Responsible for managing actions, relative to the survey.
   */
  Survey: "survey",
};

/**
 * Set of client modules reducers.
 */
export const ClientModulesReducers = {
  [ClientModules.Account]: accountReducer,
  [ClientModules.Application]: applicationReducer,
  [ClientModules.Authorization]: authorizationReducer,
  [ClientModules.Holdings]: holdingsReducer,
  [ClientModules.Notifications]: notificationsReducer,
  [ClientModules.PaymentMethod]: paymentMethodReducer,
  [ClientModules.Phone]: phoneReducer,
  [ClientModules.Subscription]: subscriptionReducer,
};

/**
 * Set of client modules sagas.
 */
export const ClientModulesSagas = {
  [ClientModules.Account]: accountSaga,
  [ClientModules.Authorization]: authorizationSaga,
  [ClientModules.Holdings]: holdingsSaga,
  [ClientModules.Notifications]: notificationsSaga,
  [ClientModules.PaymentMethod]: paymentMethodSaga,
  [ClientModules.Phone]: phoneSaga,
  [ClientModules.Subscription]: subscriptionSaga,
  [ClientModules.Support]: supportSaga,
  [ClientModules.Survey]: surveySaga,
};
