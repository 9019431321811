import moment from "moment";

import { HOLDINGS_DATE_FORMAT } from "@aft/constants/data/holdings";

/**
 * Validates whether provided search query object satisfies to the requirements.
 * Appends it with default values to be fully-filled.
 *
 * @param query - Query object to validate.
 */
export const searchHoldingsQueryGuard = (query) => ({
  ...(query.date !== undefined && moment.utc(query.date, HOLDINGS_DATE_FORMAT, true).isValid()
    ? { date: query.date }
    : {}),
});
