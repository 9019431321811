import { createWrapper } from "next-redux-wrapper";
import { createStore as createReduxStore, applyMiddleware, combineReducers } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";

import { sessionActionTypes } from "@aft/client-modules/session";
import { settings } from "@aft/client-settings";

import { ClientModulesReducers, ClientModulesSagas } from "./constants";

/**
 * Create root reducer, based on the list of the passed modules.
 *
 * @param modules - List of the modules to create root reducer from.
 */
const createRootReducer = (modules) => {
  const combinedReducer = combineReducers(
    Object.fromEntries(
      modules
        .filter((module) => !!ClientModulesReducers[module])
        .map((module) => [module, ClientModulesReducers[module]]),
    ),
  );

  return (state, action) => {
    switch (action.type) {
      case sessionActionTypes.CLEAR_SESSION:
        return combinedReducer(undefined, action);
      default:
        return combinedReducer(state, action);
    }
  };
};

/**
 * Create root saga, based on the list of the passed modules.
 *
 * @param modules - List of the modules to create root saga from.
 */
const createRootSaga = (modules) => {
  return function* () {
    yield all(
      modules
        .filter((module) => !!ClientModulesSagas[module])
        .map((module) => fork(ClientModulesSagas[module])),
    );
  };
};

/**
 * Generates create store function, based on the list of the passed modules.
 *
 * @param modules - List of the modules to create store from.
 */
const generateStoreCreator = (modules) => () => {
  const logger = createLogger({
    collapsed: true,
    level: "info",
  });

  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    ...((!settings.Application.IsProductionMode || settings.Redux.Logger.Enable) &&
    !settings.Redux.Logger.Disable
      ? [logger]
      : []),
    sagaMiddleware,
  ];

  const enhancer = applyMiddleware(...middleware);

  const store = createReduxStore(createRootReducer(modules), enhancer);

  store.sagaTask = sagaMiddleware.run(createRootSaga(modules));

  return store;
};

/**
 * Create store wrapper, based on the list of the passed modules.
 *
 * @param modules - List of the modules to create store from.
 */
const createStoreWrapper = (modules) =>
  createWrapper(generateStoreCreator(modules), {
    debug: false,
    serializeState: (state) => JSON.stringify(state),
    deserializeState: (state) => JSON.parse(state),
  });

/**
 * Class for client service modules management.
 */
export class ClientModulesManager {
  TAG = "[ClientModulesManager]";

  /**
   * Redux wrapper instance.
   */
  reduxWrapper;

  /**
   * Initializes {@link ClientModulesManager} with the provided configuration.
   *
   * @param modules - List of modules to create store wrapper from.
   */
  initialize(modules) {
    this.reduxWrapper = createStoreWrapper(modules);
  }
}
